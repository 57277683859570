.toolbarCube {
  border: 1px solid black !important;
  width: 24px;
  height: 24px;

  transition: width 200ms ease-out, height 200ms ease-out;

  &.selected {
    width: 30px;
    height: 30px;
  }
}

.cubeSelect {
  height: 34px;
}

.tool {
  padding: 0;

  &.selected {
    border: 1px solid black;
  }
}

.modifiers {
  margin-left: auto;
}

.mirrorVertically {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.container {
  width: 50vw;

  @media screen and (orientation: portrait) {
    width: 65vw;
  }

  @media screen and (orientation: landscape) {
    width: 60vh;
  }
}

.mosaic {
  cursor: none;
}

.cursor {
  display: flex;
  position: fixed;
  top: -1000px;
  left: -1000px;
  pointer-events: none;
  border: 1px solid black;
  box-shadow: 5px 5px 8px 0 rgba(0, 0, 0, 0.4);
}

.section {
  margin-top: 12px;
}
